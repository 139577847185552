<!-- 
	This is the dashboard page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

<template>
	<div>

		<a-row :gutter="24" class="" style="margin-top: 20px; margin-bottom: 20px; padding-left: 20px;">
			<a-col :span="24" class="">
				<h6>MONTHLY SUMMARY FILTER</h6>
				<hr style="color: #868181"/>
			</a-col>
		</a-row>

		<a-row :gutter="24" type="flex" align="stretch" class="py-0" style="margin-top: 20px;">
			
			<a-col :span="24" :md="24" :lg="24" :xl="24" class="mb-24">
				<a-card :bordered="false" class="bg-gray-100 header-solid h-full py-0" style="padding-top: 0px; padding-bottom: 0px;">
					<label class="mr-20">Select Month</label>
					<a-select v-model="dashboardDetailsCooked.monthNumber" default-value="8" style="width: 120px" class="text-right mr-20">
						<a-select-option value="1">January</a-select-option>
						<a-select-option value="2">February</a-select-option>
						<a-select-option value="3">March</a-select-option>
						<a-select-option value="4">April</a-select-option>
						<a-select-option value="5">May</a-select-option>
						<a-select-option value="6">June</a-select-option>
						<a-select-option value="7">July</a-select-option>
						<a-select-option value="8">August</a-select-option>
						<a-select-option value="9">September</a-select-option>
						<a-select-option value="10">October</a-select-option>
						<a-select-option value="11">November</a-select-option>
						<a-select-option value="12">December</a-select-option>
					</a-select>

					<a-button id="filterBtn" type="silabu" icon="filter" class="ml-20 text-white" @click="getDashboardDetails">
						Filter
					</a-button>
				</a-card>
			</a-col>

		</a-row>

		<a-row :gutter="24" class="mb-5" v-if="!dashboardDetailsCooked.loading">
			<a-col :span="24" :md="24" :lg="24" :xl="24" class="mb-24">
				<a-row :gutter="24" class="mb-5">

					<a-col :span="24" :md="6" :lg="6" :xl="6" class="mb-24">
						<WidgetCounter
							title="Revenue"
							:value="parseInt(dashboardDetailsCooked.revenue)"
							prefix=""
							suffix=""
							:icon="userIcon"
						></WidgetCounter>
					</a-col>


					<a-col :span="24" :md="6" :lg="6" :xl="6" class="mb-24">
						<WidgetCounter
							title="Individual Learners"
							:value="parseInt(dashboardDetailsCooked.individualLearners)"
							prefix=""
							suffix=""
							:icon="userIcon"
						></WidgetCounter>
					</a-col>

					<a-col :span="24" :md="6" :lg="6" :xl="6" class="mb-24">
						
						<WidgetCounter
							title="Male Individual Learners"
							:value="parseInt(dashboardDetailsCooked.maleIndividualLearners)"
							prefix=""
							suffix="%"
							:icon="userIcon"
						></WidgetCounter>
					</a-col>

					<a-col :span="24" :md="6" :lg="6" :xl="6" class="mb-24">
						<WidgetCounter
							title="Female Individual Learners"
							:value="parseInt(dashboardDetailsCooked.femaleIndividualLearners)"
							prefix=""
							suffix="%"
							:icon="userIcon"
						></WidgetCounter>
					</a-col>				

				</a-row>

				<a-row :gutter="24" class="mb-5">

					<a-col :span="24" :md="6" :lg="6" :xl="6" class="mb-24">
						<WidgetCounter
							title="Institutions"
							:value="parseInt(dashboardDetailsCooked.institutions)"
							prefix=""
							suffix=""
							:icon="userIcon"
						></WidgetCounter>
					</a-col>

					<a-col :span="24" :md="6" :lg="6" :xl="6" class="mb-24">
						<WidgetCounter
							title="Group Discussion Learners"
							:value="parseInt(dashboardDetailsCooked.groupDiscussionLearners)"
							prefix=""
							suffix=""
							:icon="userIcon"
						></WidgetCounter>
					</a-col>

					<a-col :span="24" :md="6" :lg="6" :xl="6" class="mb-24">
						<CardDoughnutChart 
							title="GENDER DISTRIBUTION OF INDIVIDUAL LEARNERS(%)" 
							:labels="dashboardDetailsCooked.genderLearners.labels" 
							:values="dashboardDetailsCooked.genderLearners.values">
						</CardDoughnutChart>
					</a-col>

					<a-col :span="24" :md="6" :lg="6" :xl="6" class="mb-24">
						
						<a-card :bordered="false" class="header-solid h-full pb-20" :bodyStyle="{padding: 0,}">
				
								<template #title>
									<a-row type="flex" align="middle">
										<a-col :span="24" :md="12" class="text-center">
											<h6>EDUCATION LEVEL OF LEARNERS</h6>			
										</a-col>
									</a-row>
								</template>

								<template>

									<a-table :columns="dashboardDetailsCooked.educationLevelLearnersColumns" :data-source="dashboardDetailsCooked.educationLevelLearners" :rowKey="record => record.name" :pagination="false" class="px-3">

										<!-- <template slot="s_no" slot-scope="s_no, record, index">
											<h6 class="m-0">
												{{ index + 1 }}
											</h6>
										</template> -->

										<template slot="name" slot-scope="row">
											<h6 class="m-0">
												{{ row.name }}
											</h6>
										</template>

										<template slot="total" slot-scope="row">
											<h6 class="m-0">
												{{ row.total != null ? parseInt(row.total).toLocaleString() : row.total }}
											</h6>
										</template>

									</a-table>
									
								</template>

							</a-card>
					</a-col>

					<!-- <a-col :span="24" :md="6" :lg="6" :xl="6" class="mb-24">
						<WidgetCounter
							title="Avg. Course Completion Time"
							:value="parseInt(dashboardDetailsCooked.courseAverageTime)"
							prefix=""
							suffix=""
							:icon="userIcon"
						></WidgetCounter>
					</a-col>

					<a-col :span="24" :md="6" :lg="6" :xl="6" class="mb-24">
						<WidgetCounter
							title="Avg Other Completion Time"
							:value="parseInt(dashboardDetailsCooked.othersTime)"
							prefix=""
							suffix=""
							:icon="userIcon"
						></WidgetCounter>
					</a-col>		 -->

				</a-row>

				<a-row :gutter="24" class="mb-5">

					<!-- <a-col :span="24" :md="6" :lg="6" :xl="6" class="mb-24">
						<a-row :gutter="24" class="mb-5">
							<a-col :span="24" :md="24" :lg="24" :xl="24" class="mb-24">
								<WidgetCounter
									title="Course Completition Rate"
									:value="parseInt(dashboardDetailsCooked.courseCompletionRate)"
									prefix=""
									suffix=""
									:icon="userIcon"
								></WidgetCounter>
							</a-col>
						</a-row>

						<a-row :gutter="24" class="mb-5">
							<a-col :span="24" :md="24" :lg="24" :xl="24" class="mb-24">
								<WidgetCounter
									title="Other Time"
									:value="parseInt(dashboardDetailsCooked.othersTime)"
									prefix=""
									suffix=""
									:icon="userIcon"
								></WidgetCounter>
							</a-col>
						</a-row>
					</a-col> -->

					<!-- <a-col :span="24" :md="6" :lg="6" :xl="6" class="mb-24">
						<CardDoughnutChart 
							title="GENDER DISTRIBUTION OF INDIVIDUAL LEARNERS(%)" 
							:labels="dashboardDetailsCooked.genderLearners.labels" 
							:values="dashboardDetailsCooked.genderLearners.values">
						</CardDoughnutChart>
					</a-col>

					<a-col :span="24" :md="12" :lg="12" :xl="12" class="mb-24">
						
						<a-card :bordered="false" class="header-solid h-full pb-20" :bodyStyle="{padding: 0,}">
				
								<template #title>
									<a-row type="flex" align="middle">
										<a-col :span="24" :md="12">
											<h6>EDUCATION LEVEL OF LEARNERS</h6>			
										</a-col>
									</a-row>
								</template>

								<template>

									<a-table :columns="dashboardDetailsCooked.educationLevelLearnersColumns" :data-source="dashboardDetailsCooked.educationLevelLearners" :rowKey="record => record.name" :pagination="false" class="px-3">

										<template slot="s_no" slot-scope="s_no, record, index">
											<h6 class="m-0">
												{{ index + 1 }}
											</h6>
										</template>

										<template slot="name" slot-scope="row">
											<h6 class="m-0">
												{{ row.name }}
											</h6>
										</template>

										<template slot="total" slot-scope="row">
											<h6 class="m-0">
												{{ row.total != null ? parseInt(row.total).toLocaleString() : row.total }}
											</h6>
										</template>

									</a-table>
									
								</template>

							</a-card>
					</a-col> -->

				</a-row>
			</a-col>
		</a-row>

		<div v-if="dashboardDetailsCooked.loading">

			<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="" style="min-height: 80vh">

				<a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center">
					<a-spin size="large" class="text-silabu"/>
				</a-col>

			</a-row>
		</div>


		<a-row :gutter="24" class="" style="margin-top: 40px; margin-bottom: 10px; padding-left: 20px;">
			<a-col :span="24" class="">
				<h6>PERIODIC SUMMARY FILTER</h6>
				<hr style="color: #868181"/>
			</a-col>
		</a-row>


		<a-row :gutter="24" type="flex" align="stretch" class="py-0" style="margin-top: 40px;">
			
			<a-col :span="24" :md="24" :lg="24" :xl="24" class="mb-24">
				<a-card :bordered="false" class="bg-gray-100 header-solid h-full py-0" style="padding-top: 0px; padding-bottom: 0px;">
					<a-form-model layout="inline" :form="form" @submit="handleSubmit">
						<a-row class="py-0">
							<a-col :span="24" :sm="5" :md="5" :lg="5" :xl="5" class="">
								<a-form-item label="From">
									<a-date-picker 
                      					:disabled-date="disabledStartDate"
										format="DD MMM YYYY"
										v-model="onlinePaymentFilter.startDate" />
								</a-form-item>
							</a-col>
							
							<a-col :span="24" :sm="5" :md="5" :lg="5" :xl="5" class="">
								<a-form-item label="To">
									<a-date-picker 
                      					:disabled-date="disabledEndDate"
										format="DD MMM YYYY"
										v-model="onlinePaymentFilter.endDate" />
								</a-form-item>
							</a-col>
							
							<a-col :span="24" :sm="5" :md="5" :lg="5" :xl="5" class="">
								<label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
								<a-form-item>
									<a-button id="filterBtn" type="primary" class="bg-primary" html-type="submit">
										Submit
									</a-button>
								</a-form-item>
							</a-col>
						
						</a-row>
					</a-form-model>
				</a-card>
			</a-col>
			
		</a-row>

		<div v-if="!loadingData">
			<!-- Counter Widgets -->

			<a-row :gutter="24" class="" style="margin-top: 20px; margin-bottom: 20px; padding-left: 20px;">
				<a-col :span="24" class="">
					<h6>ONLINE SECTION</h6>
					<hr style="color: #868181"/>
				</a-col>
			</a-row>

			<a-row :gutter="24">

				<a-col :span="24" :md="6" :lg="6" :xl="6" class="mb-24">
					
					<WidgetCounter
						title="Online Students"
						:value="parseInt(statistics.studentUsersNum)"
						prefix=""
						suffix=""
						:icon="userIcon"
					></WidgetCounter>
				</a-col>

				<a-col :span="24" :md="6" :lg="6" :xl="6" class="mb-24">
					<WidgetCounter
						title="Online Tutors"
						:value="parseInt(statistics.tutorUsersNum)"
						prefix=""
						suffix=""
						:icon="userIcon"
					></WidgetCounter>
				</a-col>

				<a-col :span="24" :md="6" :lg="6" :xl="6" class="mb-24">
					
					<WidgetCounter
						title="Online Active Students"
						:value="parseInt(statistics.activeStudentNum)"
						prefix=""
						suffix=""
						:icon="userIcon"
					></WidgetCounter>
				</a-col>

				<a-col :span="24" :md="6" :lg="6" :xl="6" class="mb-24">
					<WidgetCounter
						title="Online Active Tutors"
						:value="parseInt(statistics.activeTutorNum)"
						prefix=""
						suffix=""
						:icon="userIcon"
					></WidgetCounter>
				</a-col>


				<!-- {{ statistics }} -->

			</a-row>

			<a-row :gutter="24">

				<a-col :span="24" :md="6" :lg="6" :xl="6" class="mb-24">
					<WidgetCounter
						title="Group Classes"
						:value="parseInt(statistics.classesNum - statistics.bookingClassesNum)"
						prefix=""
						suffix=""
						:icon="userIcon"
					></WidgetCounter>
				</a-col>

				<a-col :span="24" :md="6" :lg="6" :xl="6" class="mb-24">
					<WidgetCounter
						title="One to One Classes"
						:value="parseInt(statistics.bookingClassesNum)"
						prefix=""
						suffix=""
						:icon="userIcon"
					></WidgetCounter>
				</a-col>

				<a-col :span="24" :md="6" :lg="6" :xl="6" class="mb-24">
					
					<WidgetCounter
						title="Peer to Peer Classes"
						:value="parseInt(statistics.peerToPeerClassesNum)"
						prefix=""
						suffix=""
						:icon="userIcon"
					></WidgetCounter>
				</a-col>

				<a-col :span="24" :md="6" :lg="6" :xl="6" class="mb-24">
					<WidgetCounter
						title="Paid Classes"
						:value="parseInt(statistics.paidClassesNum)"
						prefix=""
						suffix=""
						:icon="userIcon"
					></WidgetCounter>
				</a-col>				

			</a-row>

			<a-row :gutter="24" type="flex" align="stretch">
				<!-- <a-col :span="24" :md="6" :lg="6" :xl="6" class="mb-24">
					<WidgetCounter
						title="Downloads"
						:value="parseInt(statistics.appDownloadsNum)"
						prefix=""
						suffix=""
						:icon="userIcon"
					></WidgetCounter>
				</a-col>

				<a-col :span="24" :md="6" :lg="6" :xl="6" class="mb-24">
					<WidgetCounter
						title="Institutions"
						:value="parseInt(statistics.institutionsNum)"
						prefix=""
						suffix=""
						:icon="userIcon"
					></WidgetCounter>
				</a-col>

				<a-col :span="24" :md="6" :lg="6" :xl="6" class="mb-24">
					<CardDoughnutChart 
						title="APP DOWNLOADS" 
						:labels="statistics.appDownloadStatistics.labels" 
						:values="statistics.appDownloadStatistics.values">
					</CardDoughnutChart>
				</a-col> -->
			</a-row>


			<a-row :gutter="24" class="" style="margin-top: 20px; margin-bottom: 20px; padding-left: 20px;">
				<a-col :span="24" class="">
					<h6>OFFLINE SECTION</h6>
					<hr style="color: #868181"/>
				</a-col>
			</a-row>


			<a-row :gutter="24">

				<a-col :span="24" :md="6" :lg="6" :xl="6" class="mb-24">
					
					<WidgetCounter
						title="Offline Students"
						:value="parseInt(statistics.offlineStudentNum)"
						prefix=""
						suffix=""
						:icon="userIcon"
					></WidgetCounter>
				</a-col>

				<a-col :span="24" :md="6" :lg="6" :xl="6" class="mb-24">
					<WidgetCounter
						title="Offline Classes"
						:value="parseInt(statistics.offlineBookingNum)"
						prefix=""
						suffix=""
						:icon="userIcon"
					></WidgetCounter>
				</a-col>

				<!-- <a-col :span="24" :md="6" :lg="6" :xl="6" class="mb-24">
					
					<WidgetCounter
						title="Online Active Students"
						:value="parseInt(statistics.activeStudentNum)"
						prefix=""
						suffix=""
						:icon="userIcon"
					></WidgetCounter>
				</a-col>

				<a-col :span="24" :md="6" :lg="6" :xl="6" class="mb-24">
					<WidgetCounter
						title="Online Active Tutors"
						:value="parseInt(statistics.activeTutorNum)"
						prefix=""
						suffix=""
						:icon="userIcon"
					></WidgetCounter>
				</a-col> -->


				<!-- {{ statistics }} -->

			</a-row>

			<a-row :gutter="24" class="" style="margin-top: 30px; margin-bottom: 20px; padding-left: 20px;">
				<a-col :span="24" class="">
					<h6>APP DOWNLOADS SECTION</h6>
					<hr style="color: #868181"/>
				</a-col>
			</a-row>

			<a-row :gutter="24" type="flex" align="stretch">
				<a-col :span="24" :md="6" :lg="6" :xl="6" class="mb-24">
					<WidgetCounter
						title="Android Downloads"
						:value="statistics.androidDownloadStatistics.value != null ? parseInt(statistics.androidDownloadStatistics.value) : 0"
						prefix=""
						suffix=""
						:icon="userIcon"
					></WidgetCounter>
				</a-col>

				<a-col :span="24" :md="6" :lg="6" :xl="6" class="mb-24">
					<WidgetCounter
						title="Ios Downloads"
						:value="statistics.iosDownloadStatistic.value != null ? parseInt(statistics.iosDownloadStatistic.value) : 0"
						prefix=""
						suffix=""
						:icon="userIcon"
					></WidgetCounter>
				</a-col>

			</a-row>

			
			<!-- <a-row :gutter="24" type="flex" align="stretch">
				<a-col :span="24" :lg="12" class="mb-24">
					<CardLineChart></CardLineChart>
				</a-col>
				<a-col :span="24" :lg="6" class="mb-24">
					<CardDoughnutChart 
						title="Student Gender Chart" 
						:labels="statistics.studentGenderUsersNum.labels" 
						:values="statistics.studentGenderUsersNum.values">
					</CardDoughnutChart>
				</a-col>
				<a-col :span="24" :lg="6" class="mb-24">
					<CardDoughnutChart 
						title="APP DOWNLOADS" 
						:labels="statistics.appDownloadStatistics.labels" 
						:values="statistics.appDownloadStatistics.values">
					</CardDoughnutChart>
				</a-col>
			</a-row> -->
			<!-- / Charts -->


			<div v-if="onlinePaymentFilter.loading">

				<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="" style="min-height: 30vh">

					<a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center">
						<double-bounce class="" ></double-bounce>
					</a-col>

				</a-row>
			</div>
			<!-- / Table & Timeline -->

			<!-- Cards -->
			<!-- <a-row :gutter="24" type="flex" align="stretch">
				<a-col :span="24" :xl="14" class="mb-24">

					<CardInfo></CardInfo>

				</a-col>
				<a-col :span="24" :xl="10" class="mb-24">

					<CardInfo2></CardInfo2>
				</a-col>
			</a-row> -->
			<!-- / Cards -->

		</div>

		<div v-if="loadingData">

			<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="" style="min-height: 80vh">

				<a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center">
					<double-bounce class="" ></double-bounce>
				</a-col>

			</a-row>
		</div>
	</div>
</template>

<script>
	import dayjs from 'dayjs';
	import { notification } from 'ant-design-vue';
	import { DoubleBounce } from 'vue-loading-spinner'
	import CardBarChart from '../components/Cards/CardBarChart' ;
	import CardLineChart from '../components/Cards/CardLineChart' ;
	import CardDoughnutChart from '../components/Cards/CardDoughnutChart' ;

	// Counter Widgets
	import WidgetCounter from '../components/Widgets/WidgetCounter' ;

	// "Projects" table component.
	import CardProjectTable from '../components/Cards/CardProjectTable' ;

	// Order History card component.
	import CardOrderHistory from '../components/Cards/CardOrderHistory' ;

	// Information card 1.
	import CardInfo from '../components/Cards/CardInfo' ;

	// Information card 2.
	import CardInfo2 from '../components/Cards/CardInfo2' ;
	
	const userIcon = `
						<svg width="22" height="22" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M9 6C9 7.65685 7.65685 9 6 9C4.34315 9 3 7.65685 3 6C3 4.34315 4.34315 3 6 3C7.65685 3 9 4.34315 9 6Z" fill="#111827"/>
							<path d="M17 6C17 7.65685 15.6569 9 14 9C12.3431 9 11 7.65685 11 6C11 4.34315 12.3431 3 14 3C15.6569 3 17 4.34315 17 6Z" fill="#111827"/>
							<path d="M12.9291 17C12.9758 16.6734 13 16.3395 13 16C13 14.3648 12.4393 12.8606 11.4998 11.6691C12.2352 11.2435 13.0892 11 14 11C16.7614 11 19 13.2386 19 16V17H12.9291Z" fill="#111827"/>
							<path d="M6 11C8.76142 11 11 13.2386 11 16V17H1V16C1 13.2386 3.23858 11 6 11Z" fill="#111827"/>
						</svg>
					`

	// Counter Widgets stats
	const stats = [
		{
			title: "Today’s Sales",
			value: 53000,
			prefix: "$",
			suffix: "+30%",
			icon: `
						<svg width="22" height="22" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M8.43338 7.41784C8.58818 7.31464 8.77939 7.2224 9 7.15101L9.00001 8.84899C8.77939 8.7776 8.58818 8.68536 8.43338 8.58216C8.06927 8.33942 8 8.1139 8 8C8 7.8861 8.06927 7.66058 8.43338 7.41784Z" fill="#111827"/>
							<path d="M11 12.849L11 11.151C11.2206 11.2224 11.4118 11.3146 11.5666 11.4178C11.9308 11.6606 12 11.8861 12 12C12 12.1139 11.9308 12.3394 11.5666 12.5822C11.4118 12.6854 11.2206 12.7776 11 12.849Z" fill="#111827"/>
							<path fill-rule="evenodd" clip-rule="evenodd" d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM11 5C11 4.44772 10.5523 4 10 4C9.44772 4 9 4.44772 9 5V5.09199C8.3784 5.20873 7.80348 5.43407 7.32398 5.75374C6.6023 6.23485 6 7.00933 6 8C6 8.99067 6.6023 9.76515 7.32398 10.2463C7.80348 10.5659 8.37841 10.7913 9.00001 10.908L9.00002 12.8492C8.60902 12.7223 8.31917 12.5319 8.15667 12.3446C7.79471 11.9275 7.16313 11.8827 6.74599 12.2447C6.32885 12.6067 6.28411 13.2382 6.64607 13.6554C7.20855 14.3036 8.05956 14.7308 9 14.9076L9 15C8.99999 15.5523 9.44769 16 9.99998 16C10.5523 16 11 15.5523 11 15L11 14.908C11.6216 14.7913 12.1965 14.5659 12.676 14.2463C13.3977 13.7651 14 12.9907 14 12C14 11.0093 13.3977 10.2348 12.676 9.75373C12.1965 9.43407 11.6216 9.20873 11 9.09199L11 7.15075C11.391 7.27771 11.6808 7.4681 11.8434 7.65538C12.2053 8.07252 12.8369 8.11726 13.254 7.7553C13.6712 7.39335 13.7159 6.76176 13.354 6.34462C12.7915 5.69637 11.9405 5.26915 11 5.09236V5Z" fill="#111827"/>
						</svg>`,
		},
		{
			title: "Today’s Users",
			value: 3200,
			suffix: "+20%",
			icon: `
						<svg width="22" height="22" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M9 6C9 7.65685 7.65685 9 6 9C4.34315 9 3 7.65685 3 6C3 4.34315 4.34315 3 6 3C7.65685 3 9 4.34315 9 6Z" fill="#111827"/>
							<path d="M17 6C17 7.65685 15.6569 9 14 9C12.3431 9 11 7.65685 11 6C11 4.34315 12.3431 3 14 3C15.6569 3 17 4.34315 17 6Z" fill="#111827"/>
							<path d="M12.9291 17C12.9758 16.6734 13 16.3395 13 16C13 14.3648 12.4393 12.8606 11.4998 11.6691C12.2352 11.2435 13.0892 11 14 11C16.7614 11 19 13.2386 19 16V17H12.9291Z" fill="#111827"/>
							<path d="M6 11C8.76142 11 11 13.2386 11 16V17H1V16C1 13.2386 3.23858 11 6 11Z" fill="#111827"/>
						</svg>`,
		},
		{
			title: "New Clients",
			value: 1200,
			prefix: "+",
			status: "danger",
			suffix: "-20%",
			icon: `
						<svg width="22" height="22" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path fill-rule="evenodd" clip-rule="evenodd" d="M3.17157 5.17157C4.73367 3.60948 7.26633 3.60948 8.82843 5.17157L10 6.34315L11.1716 5.17157C12.7337 3.60948 15.2663 3.60948 16.8284 5.17157C18.3905 6.73367 18.3905 9.26633 16.8284 10.8284L10 17.6569L3.17157 10.8284C1.60948 9.26633 1.60948 6.73367 3.17157 5.17157Z" fill="#111827"/>
						</svg>`,
		},
		{
			title: "New Orders",
			value: 13200,
			prefix: "$",
			suffix: "+10%",
			icon: `
						<svg width="22" height="22" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path fill-rule="evenodd" clip-rule="evenodd" d="M10 2C7.79086 2 6 3.79086 6 6V7H5C4.49046 7 4.06239 7.38314 4.00612 7.88957L3.00612 16.8896C2.97471 17.1723 3.06518 17.455 3.25488 17.6669C3.44458 17.8789 3.71556 18 4 18H16C16.2844 18 16.5554 17.8789 16.7451 17.6669C16.9348 17.455 17.0253 17.1723 16.9939 16.8896L15.9939 7.88957C15.9376 7.38314 15.5096 7 15 7H14V6C14 3.79086 12.2091 2 10 2ZM12 7V6C12 4.89543 11.1046 4 10 4C8.89543 4 8 4.89543 8 6V7H12ZM6 10C6 9.44772 6.44772 9 7 9C7.55228 9 8 9.44772 8 10C8 10.5523 7.55228 11 7 11C6.44772 11 6 10.5523 6 10ZM13 9C12.4477 9 12 9.44772 12 10C12 10.5523 12.4477 11 13 11C13.5523 11 14 10.5523 14 10C14 9.44772 13.5523 9 13 9Z" fill="#111827"/>
						</svg>`,
		},
	] ;

	// "Projects" table list of columns and their properties.
	const tableColumns = [
		{
			title: 'CLASS',
			scopedSlots: { customRender: 'class' },
			class: 'font-bold',
			width: 250,
		},
		{
			title: 'CLASS TYPE',
			scopedSlots: { customRender: 'classType' },
			class: 'text-muted text-sm',
		},
		{
			title: 'AMOUNT',
			dataIndex: 'totalAmount',
		},
		
		// {
		// 	title: 'CLASS TYPE',
		// 	dataIndex: 'itemType',
		// 	class: 'font-bold text-muted text-sm',
		// },
		// {
		// 	title: 'CLASS',
		// 	scopedSlots: { customRender: 'class' },
		// 	class: 'font-normal text-muted text-sm',
		// },
		{
			title: 'DATE',
			scopedSlots: { customRender: 'createdAt' },
			class: 'text-muted text-sm',
		},
	];

	// "Projects" table list of rows and their properties.
	const tableData = [
		{
			key: '1',
			company: {
				name: 'Soft UI Shopify Version',
				logo: 'images/logos/logo-shopify.svg',
			},
			members: [ "images/face-1.jpg", "images/face-4.jpg", "images/face-2.jpg", "images/face-3.jpg", ],
			budget: '$14,000',
			completion: 60,
		},
		{
			key: '2',
			company: {
				name: 'Progress Track',
				logo: 'images/logos/logo-atlassian.svg',
			},
			members: [ "images/face-4.jpg", "images/face-3.jpg", ],
			budget: '$3,000',
			completion: 10,
		},
		{
			key: '3',
			company: {
				name: 'Fix Platform Errors',
				logo: 'images/logos/logo-slack.svg',
			},
			members: [ "images/face-1.jpg", "images/face-2.jpg", "images/face-3.jpg", ],
			budget: 'Not Set',
			completion: {
				label: '100',
				status: 'success',
				value: 100,
			},
		},
		{
			key: '4',
			company: {
				name: 'Launch new Mobile App',
				logo: 'images/logos/logo-spotify.svg',
			},
			members: [ "images/face-1.jpg", "images/face-2.jpg", ],
			budget: '$20,600',
			completion: {
				label: '100',
				status: 'success',
				value: 100,
			},
		},
		{
			key: '5',
			company: {
				name: 'Add the New Landing Page',
				logo: 'images/logos/logo-jira.svg',
			},
			members: [ "images/face-1.jpg", "images/face-4.jpg", "images/face-2.jpg", "images/face-3.jpg", ],
			budget: '$4,000',
			completion: 80,
		},
		{
			key: '6',
			company: {
				name: 'Redesign Online Store',
				logo: 'images/logos/logo-invision.svg',
			},
			members: [ "images/face-1.jpg", "images/face-4.jpg", "images/face-3.jpg", ],
			budget: '$2,000',
			completion: {
				label: 'Cancelled',
				status: 'exception',
				value: 100,
			},
		},
		
	];

	const current = new Date();

	const date = `${current.getFullYear()}-${current.getMonth().toString().length === 1 ? '0' : ''}${current.getMonth()+1}-${current.getDate().toString().length === 1 ? '0' : ''}${current.getDate()}`;

	const educationLevelLearnersColumns = [
		// {
		// 	title: 'S/N',
		// 	scopedSlots: { customRender: 's_no' },
		// 	class: 'font-bold',
		// },
		{
			title: 'Education Level',
			scopedSlots: { customRender: 'name' },
			class: 'font-bold',
		},
		{
			title: 'Total',
			scopedSlots: { customRender: 'total' },
			class: 'font-bold',
		},
	]
	export default ({
		components: {
			CardBarChart,
			CardLineChart,
			CardDoughnutChart,
			WidgetCounter,
			CardProjectTable,
			CardOrderHistory,
			CardInfo,
			CardInfo2,
			DoubleBounce,
		},
		data() {
			return {

				form: this.$form.createForm(this, { name: 'normal_login' }),

				loadingData: false,
				loadingData1: true,
				// Associating table data with its corresponding property.
				tableData,

				// Associating table columns with its corresponding property.
				tableColumns,

				// Counter Widgets Stats
				stats,

				userIcon,
				
      
				onlinePaymentFilter: {
					labels: [],
					values: [],
					startDate: null,
					endDate: null,
					loading: false,
				},

				topPayingClassesFilter: [],

				statistics: {
					loading: false,
					iosDownloadStatistic: {
						labels: 0,
						values: 0,
					},
					androidDownloadStatistics: {
						labels: 0,
						values: 0,
					},
					offlinePayment: 0,
					onlinePayment: 0,
					studentUsersNum: 0,
					activeStudentNum: 0,
					activeTutorNum: 0,
					offlineStudentNum: 0,
					offlineBookingNum: 0,
					tutorUsersNum: 0,
					studentGenderUsersNum: {
						labels: ['Male', 'Female'],
						values: []
					},
					onlinePaymentAmount: 0,
					classesNum: 0,
					bookingClassesNum: 0,
					totalAppDownloads: 0,
					appDownloadsNum: 0,
					institutionsNum: 0,
					peerToPeerClassesNum: 0,
					paidClassesNum: 0,
					totalSubscribersNum: 0,
					totalAppDownloadsNum: 0,
					totalOfflinePayments: 0,
					totalOnlinePaymentAmount: 0,
					totalStudentNum: 0,
					totalTutorNum: 0,
					totalVodaBundleGrants: 0,
					totalUsersNum: 0,
					totalInstituteAdminNum: 0,
					appDownloadStatistics: {
						labels: ['New Downloads', 'Re-Downloads', 'Uninstalls'],
						values: []
					},
				},

				userMonthlyReport: {
					labels: [],
					values: [],
					year: 2023,
				},

				dashboardDetailsCooked: {
					loading: false,
					monthName: "August", 
					monthNumber: "8",
					revenue: 0,
					institutions: 0,
					individualLearners: 0, 
					maleIndividualLearners: 0, 
					femaleIndividualLearners: 0, 
					childhoodLevelLearners: 0, 
					k12LevelLearners: 0, 
					tertiaryLevelLearners: 0, 
					corporateLevelLearners: 0, 
					vocationalLevelLearners: 0, 
					courseAverageTime: 0, 
					othersTime: 0, 
					courseCompletionRate: 0, 
					groupDiscussionLearners: 0,
					educationLevelLearners: [],
					genderLearners: {
						labels: [],
						values: []
					},
					educationLevelLearnersColumns,
				},

			}
		},
		mounted() {
			this.defaultDate()
			this.getDashboardDetails()
			this.getNumStatistics()
			// this.getOnlinePaymentFilter()
			// this.getUserMonthlyReport()
		},
		methods: {

			defaultDate() {
				this.onlinePaymentFilter.startDate = dayjs(this.moment(this.getLastWeeksDate()).format("YYYY-MM-DD"), "YYYY-MM-DD");
				this.onlinePaymentFilter.endDate = dayjs(this.moment(new Date()).format("YYYY-MM-DD"), "YYYY-MM-DD");
			},

			getLastWeeksDate() {
				const now = new Date();

				return new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7);
			},

			disabledStartDate(current) {
				// Can not select days before today and today
				return current > this.onlinePaymentFilter.endDate;
			},

			disabledEndDate(current) {
				// Can not select days before today and today
				return current && (current < this.onlinePaymentFilter.startDate || current > dayjs().endOf('day'));
			},

			async setDefaultValues() {
				
				this.statistics.iosDownloadStatistic.labels = 0;
				this.statistics.iosDownloadStatistic.value = 0;
						
				this.statistics.androidDownloadStatistics.labels = 0;
				this.statistics.androidDownloadStatistics.values = 0;
					
				this.statistics.offlinePayment = 0;
				this.statistics.onlinePayment = 0;
				this.statistics.studentUsersNum = 0;
				this.statistics.tutorUsersNum = 0;
				this.statistics.offlineStudentNum = 0;
				this.statistics.offlineBookingNum =  0;

				this.statistics.studentGenderUsersNum.labels = ['Male', 'Female'];
				this.statistics.studentGenderUsersNum.values = [];
		
				this.statistics.onlinePaymentAmount = 0;
				this.statistics.classesNum = 0;
				this.statistics.bookingClassesNum = 0;
				this.statistics.totalAppDownloads = 0;
				this.statistics.appDownloadsNum = 0;
				this.statistics.institutionsNum = 0;
				this.statistics.peerToPeerClassesNum = 0;
				this.statistics.paidClassesNum = 0;
				this.statistics.appDownloadStatistics.labels = ['New Downloads', 'Re-Downloads', 'Uninstalls'];
				this.statistics.appDownloadStatistics.values = [];
				
			},

			async getNumStatistics() {
                this.loadingData = true;

				this.setDefaultValues();
				
				let { startDate, endDate } = this.onlinePaymentFilter;

				startDate = this.moment(startDate.$d != null ? startDate.$d : startDate._d).format("YYYY-MM-DD")
				endDate = this.moment(endDate.$d != null ? endDate.$d : endDate._d).format("YYYY-MM-DD")

				let token = await localStorage.getItem("user_token")

                let url = `${this.$BACKEND_URL}/investor-reports?startDate=${startDate}&endDate=${endDate}`;

				const config = {
					headers: {
						'Content-Type': 'application/json',
						'Authorization': `Bearer ${token}`,
					},
				};

                this.$AXIOS.get(url, config).then(async (response) => {
                    if (response.status == 200) {
						
						if(response.data.studentGenderUsersNum !== null && response.data.studentGenderUsersNum.length > 1) {
							this.statistics.studentGenderUsersNum.values = [response.data.studentGenderUsersNum[1].count, response.data.studentGenderUsersNum[0].count];
						}

						this.statistics.totalSubscribersNum = response.data.totalSubscribersNum;
						this.statistics.totalAppDownloadsNum = response.data.totalAppDownloadsNum;
						this.statistics.totalOfflinePayments = response.data.totalOfflinePayments;
						this.statistics.totalOnlinePaymentAmount = response.data.totalOnlinePaymentAmount;
						this.statistics.totalStudentNum = response.data.totalStudentNum;
						this.statistics.totalTutorNum = response.data.totalTutorNum;
						this.statistics.totalVodaBundleGrants = response.data.totalVodaBundleGrants;
						this.statistics.totalUsersNum = response.data.totalUsersNum;
						this.statistics.totalInstituteAdminNum = response.data.totalInstituteAdminNum;


						this.statistics.studentUsersNum = response.data.studentUsersNum;
						this.statistics.tutorUsersNum = response.data.tutorUsersNum;
						this.statistics.activeStudentNum = response.data.activeStudentNum;
						this.statistics.activeTutorNum = response.data.activeTutorNum;
						this.statistics.classesNum = response.data.classesNum,							
						this.statistics.bookingClassesNum = response.data.bookingClassesNum;
						this.statistics.peerToPeerClassesNum = response.data.peerToPeerClassesNum;
						this.statistics.paidClassesNum = response.data.paidClassesNum;
						this.statistics.offlineBookingNum = response.data.offlineBookingNum != null ? response.data.offlineBookingNum : 0,							
						this.statistics.offlineStudentNum = response.data.offlineStudentNum != null ? response.data.offlineStudentNum : 0;

						


						if(response.data.onlinePaymentAmount !== null) {
							this.statistics.onlinePaymentAmount = response.data.onlinePaymentAmount.totalAmount;
						}

						this.statistics.onlinePayment = response.data.onlinePayments;
						this.statistics.institutionsNum = response.data.institutionsNum;

						if(response.data.appDownloadsNum !== null) {			
							this.statistics.appDownloadsNum = response.data.appDownloadsNum.totalNumber;		
						}		
						
						
						if(response.data.appDownloadStatistic !== null && response.data.appDownloadStatistic.length > 0) {
							this.statistics.appDownloadStatistics.labels = response.data.appDownloadStatistic.map(stat => this.getCategoryName(stat.category));		
							
							
							this.statistics.appDownloadStatistics.values = response.data.appDownloadStatistic.map(stat => stat.totalNumber);
							// console.log("==================================================>")
							// console.log(response.data.appDownloadStatistic.map(stat => stat.totalNumber))		
						}

						if (
							response.data.androidDownloadStatistics !== null &&
							response.data.androidDownloadStatistics.length > 0
						) {

							let androidDownloadStatistics = await response.data.androidDownloadStatistics.map(
								(stat) => ({
									name: this.getCategoryName(stat.category),
									value: stat.totalNumber,
								})
							);

							this.statistics.androidDownloadStatistics = androidDownloadStatistics;
						}

						if (
							response.data.iosDownloadStatistic !== null &&
							response.data.iosDownloadStatistic.length > 0
						) {
							let iosDownloadStatistic = await response.data.iosDownloadStatistic.map((stat) => ({
								name: this.getCategoryName(stat.category),
								value: stat.totalNumber,
							}));

							this.statistics.iosDownloadStatistic = iosDownloadStatistic;
						}

						if (
							response.data.offlinePayments !== null &&
							response.data.offlinePayments.length === 1
						) {
							// if (response.data.offlinePayments[0].category === 'online') {
							// 	this.statistics.onlinePayment = response.data.offlinePayments[0].amount;
							// }

							if (response.data.offlinePayments[0].category === 'offline') {
								this.statistics.offlinePayment = response.data.offlinePayments[0].totalAmount;
							}
						}

						if (response.data.offlinePayments !== null && response.data.offlinePayments.length > 1) {

							if (response.data.offlinePayments[0].category === 'offline') {
								this.statistics.offlinePayment = response.data.offlinePayments[0].totalAmount;
							}

							// if (response.data.offlinePayments[1].category === 'online') {
							// 	this.statistics.onlinePayment = response.data.offlinePayments[0].amount;
							// }
						
						}

						this.loadingData = false;


					}else{
                    	this.loadingData = false;
						this.message = "There is no data available"
					}
                    
                }).catch(async(error) => {
					console.log(error)
                    if(error.response && error.response.status == 401) {
                    	this.loadingData = false;
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)

                        this.$router.push(`/sign-in`);
                    }
                    // this.notify.message = err.response.data.message ?? "Connection error";
                    // this.notify.type = "danger"
                    // this.$notify(this.notify)

                    this.loadingData = false;
                });
            },

			async getDashboardDetails() {
                this.dashboardDetailsCooked.loading = true;

				this.setDefaultValues();
				
				let { monthNumber } = this.dashboardDetailsCooked;

				let token = await localStorage.getItem("user_token")

                let url = `${this.$BACKEND_URL}/dashboard-data-cooked-v1/month/${monthNumber}`;

				const config = {
					headers: {
						'Content-Type': 'application/json',
						'Authorization': `Bearer ${token}`,
					},
				};

                this.$AXIOS.get(url, config).then(async (response) => {
                    if (response.status == 200 && response.data != null) {

						this.dashboardDetailsCooked.revenue = response.data.revenue;
						this.dashboardDetailsCooked.institutions = response.data.institutions;
						this.dashboardDetailsCooked.individualLearners = response.data.individualLearners;
						this.dashboardDetailsCooked.maleIndividualLearners = response.data.maleIndividualLearners;
						this.dashboardDetailsCooked.femaleIndividualLearners = response.data.femaleIndividualLearners;
						this.dashboardDetailsCooked.childhoodLevelLearners = response.data.childhoodLevelLearners;
						this.dashboardDetailsCooked.k12LevelLearners = response.data.k12LevelLearners;
						this.dashboardDetailsCooked.tertiaryLevelLearners = response.data.tertiaryLevelLearners;
						this.dashboardDetailsCooked.corporateLevelLearners = response.data.corporateLevelLearners;
						this.dashboardDetailsCooked.vocationalLevelLearners = response.data.vocationalLevelLearners;
						this.dashboardDetailsCooked.courseAverageTime = response.data.courseAverageTime;
						this.dashboardDetailsCooked.othersTime = response.data.othersTime;
						this.dashboardDetailsCooked.courseCompletionRate = response.data.courseCompletionRate;
						this.dashboardDetailsCooked.groupDiscussionLearners = response.data.groupDiscussionLearners;
						this.dashboardDetailsCooked.educationLevelLearners = [
							{name: "Early Childhood", total: response.data.childhoodLevelLearners},
							{name: "K-12", total: response.data.k12LevelLearners},
							{name: "Tertiary Education", total: response.data.tertiaryLevelLearners},
							{name: "Formal/Corporate", total: response.data.corporateLevelLearners},
							{name: "Vocational", total: response.data.vocationalLevelLearners},
						];
						this.dashboardDetailsCooked.genderLearners.labels = ["Male", "Female"];
						this.dashboardDetailsCooked.genderLearners.values = [response.data.maleIndividualLearners, response.data.femaleIndividualLearners];

						this.dashboardDetailsCooked.loading = false;
					}else{
						let message = `There is no data available for ${this.getMonthName(this.dashboardDetailsCooked.monthNumber)}`

						this.notify(message, 'error')
                		this.dashboardDetailsCooked.loading = false;
					}
                    
                }).catch(async(error) => {
					console.log(error)

					this.dashboardDetailsCooked.loading = false;

                    if(error.response && error.response.status == 401) {
                    	this.loadingData = false;
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)

                        this.$router.push(`/sign-in`);
                    }
                    let message = error.response != null && error.response.message != null ? error.response.data.message : "Something went wrong, try again!";
                    this.notify(message, 'error')

                    this.loadingData = false;
                });
            },

			async getOnlinePaymentFilter() {

				this.onlinePaymentFilter.loading = true;

                let { startDate, endDate } = this.onlinePaymentFilter;

				startDate = this.moment(startDate.$d != null ? startDate.$d : startDate._d).format("YYYY-MM-DD")
				endDate = this.moment(endDate.$d != null ? endDate.$d : endDate._d).format("YYYY-MM-DD")

				let token = await localStorage.getItem("user_token")

                let url = `${this.$BACKEND_URL}/investor-reports/online-payment-filter?startDate=${startDate}&endDate=${endDate}`;

                const config = {
					headers: {
						'Content-Type': 'application/json',
						'Authorization': `Bearer ${token}`,
					},
				};

                this.$AXIOS.get(url, config).then(async(response) => {
                    if (response.status == 200) {

							this.onlinePaymentFilter.labels = await response.data.onlinePaymentFilter.map(stat => stat.itemType)
							this.onlinePaymentFilter.values = await response.data.onlinePaymentFilter.map(stat => stat.totalAmount)

							this.topPayingClassesFilter = response.data.topPayingClassesFilter.rows

							this.onlinePaymentFilter.loading = false
							// c
                            // this.onlinePaymentFilter.value = response.data;
                            // this.pagination.totalPages = response.data.totalPages;
                            // this.pagination.total = response.data.total;
                            // this.pagination. currentPage = response.data. currentPage;
							
                        
                    }
                    this.loadingData = false;
                }).catch(async(error) => {
					this.onlinePaymentFilter.loading = false
                    if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)

                        this.$router.push(`/sign-in`);
                    }
                    // this.notify.message = err.response.data.message ?? "Connection error";
                    // this.notify.type = "danger"
                    // this.$notify(this.notify)
                });
            },

			async getUserMonthlyReport() {

				let token = await localStorage.getItem("user_token")

                let url = `${this.$BACKEND_URL}/investor-reports/user-registration-report?year=${this.userMonthlyReport.year}`;

                const config = {
					headers: {
						'Content-Type': 'application/json',
						'Authorization': `Bearer ${token}`,
					},
				};

                this.$AXIOS.get(url, config).then(async(response) => {
                    if (response.status == 200) {
						this.userMonthlyReport.labels = await response.data.map(rep => this.getMonthName(rep.month))
						this.userMonthlyReport.values = await response.data.map(rep => rep.count)

						// console.log(this.userMonthlyReport.labels = await response.data.map(rep => this.getMonthName(rep.month)))
                    }
                }).catch(async(error) => {
					console.log(error)
					
                    if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)

                        this.$router.push(`/sign-in`);
                    }
                });
            },


			handleSubmit(e) {
				e.preventDefault();

				this.getOnlinePaymentFilter();
				this.getNumStatistics();
				// this.form.validateFields((err, values) => {
				// 	if ( !err ) {
				// 		this.error.show = false;
				// 		this.error.message = null;

				// 		let url = `${this.$BACKEND_URL}/auth/investor/login`;
						
				// 		this.$AXIOS.post(url, values).then(async(response) => {
				// 			if (response.status >= 200 && response.status < 210) {
				// 			await localStorage.setItem("user_token", response.data.token)
				// 			await localStorage.setItem("user_details", JSON.stringify(response.data.user))

				// 			// this.notify.message = "You have successfully login";
				// 			// this.notify.type = "success"
				// 			// this.$notify(this.notify)

				// 			this.$router.push(`/dashboard`);
				// 			}
				// 		}).catch((err) => {
				// 			this.isloading = false;
							
				// 			// this.notify.message = err.response.data.message ?? "Connection error";
				// 			// this.notify.type = "danger"
				// 			// this.$notify(this.notify)
							
				// 			this.error.message = err.response.data.message ?? "Connection error";
				// 			this.error.show = true
				// 		});
				// 	}
				// });
			},

			getCategoryName(category) {
				if ('new-downloads' === category) {
					return 'New Downloads';
				} else if ('re-downloads' === category) {
					return 'Re-Downloads';
				} else if ('uninstalls' === category) {
					return 'Uninstalls';
				} else {
					return 'Unknown';
				}
			},

			getMonthName(numPassed = 0) {
				let num = parseInt(numPassed)
				switch (num) {
					case 1:
						return 'January';
					case 2:
						return 'February';
					case 3:
						return 'March';
					case 4:
						return 'April';
					case 5:
						return 'May';
					case 6:
						return 'June';
					case 7:
						return 'July';
					case 8:
						return 'August';
					case 9:
						return 'September';
					case 10:
						return 'October';
					case 11:
						return 'November';
					case 12:
						return 'December';
				
					default:
						return "January";
				}
			},

			notify(message, type) {
                notification[type]({
                    message: message,
                    description: '',
                    placement: 'topRight',
                    duration: 3,
                });
                
            },
		}
	})

</script>

<style>

.double-bounce1[data-v-33aee302], .double-bounce2[data-v-33aee302] {
	background-color: #54398D !important;
	height: 70px;
	width: 70px;
}
#filterBtn, #filterBtn:hover {
	background-color: #54398D;
}
</style>